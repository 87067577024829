import { useRouter, useParams } from 'next/navigation'
const useLocalizedRouter = () => {
  const router = useRouter()
  const params = useParams<{ lng: string }>()

  const pushWithLocale = (url: string) => {
    router.push(`/${params.lng}/${url}`)
  }

  return pushWithLocale
}

export default useLocalizedRouter
